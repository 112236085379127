import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import CheckSharpIcon from "@mui/icons-material/CheckSharp"
import CloseIcon from "@mui/icons-material/Close"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Link from "@mui/material/Link"
import InputAdornment from "@mui/material/InputAdornment"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { useFormik } from "formik"
import { clearLocalStorageExceptPersistRoot } from "../../../constant/ClearLocalStorage"
import {
    AES256_GCM_ENCRYPT,
    AES256_GCM_decrypt
} from "../../../constant/EncryptionDecryptionUtils"
import AnimatedDotsModal from "../../common/AnimatedModal/AnimatedLoader"
import * as yup from "yup"
import { toast, ToastContainer } from "react-toastify"
import { forgotPassword } from "../../../services" // eslint-disable-next-line
import { login } from "../../../redux/slices/authSlice"
import "./login.css"
import "../../../theme/styles/globalStyles.css"
import { CircularProgress } from "@mui/material"

const validationSchema = yup.object({
    email: yup
        .string("Enter your email")
        .email("Enter a valid email")
        .required("Email is required"),

    password: yup
        .string("Enter your password")
        .matches(
            // eslint-disable-next-line
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&*.\[\]{}()?\"!@#%&/\\,><':;|_~`=+\-])[a-zA-Z\d!@#\$%\^&*.\[\]{}()?\"!@#%&/\\,><':;|_~`=+\-]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number, and One Special Case Character (^ $ * . [ ] { } ( ) ? \"! @ # % & / \\ , > < ' : ; | _ ~ ` = + -)"
        )
        .required("Password is required")
})
const forgotPasswordValidationSchema = yup.object({
    email: yup
        .string("Enter your email")
        .email("Enter a valid email")
        .required("Email is required")
})

const Login = () => {
    const [showVerified, setShowVerified] = useState(false)
    const [showinvalid, setShowinvalid] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [fpassword, setFpassword] = useState(false)
    const [searchParams] = useSearchParams()

    const fcmTokenForMobile = searchParams?.get("fcmToken")
    const [splashScreen, setsplashScreen] = useState(false)
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down("lg"))
    const isMobileTab = useMediaQuery(theme.breakpoints.down("md"))
    const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"))
    const customId = "custom-id-yes"
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isLoading = useSelector((state) => state?.auth.loading)

    const formik = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: fpassword
            ? forgotPasswordValidationSchema
            : validationSchema,
        onSubmit: async (values) => {
            let os = window.navigator.userAgent
            let finalOs = ""
            if (os.search("Windows") !== -1) {
                finalOs = "Windows"
            } else if (os.search("Mac") !== -1) {
                finalOs = "MacOS"
            } else if (
                os.search("X11") !== -1 &&
                !(os.search("Linux") !== -1)
            ) {
                finalOs = "UNIX"
            } else if (os.search("Linux") !== -1 && os.search("X11") !== -1) {
                finalOs = "Linux"
            }
            if (["Linux", "UNIX", "MacOS", "Windows"].includes(finalOs)) {
                values.isMobile = false
            } else {
                values.isMobile = true
            }
            //values.fcmToken = fcmTokenForMobile
            if (fpassword) {
                // eslint-disable-next-line
                const obj = {
                    email: values.email
                }
            } else {
                console.log(fcmTokenForMobile, "fcmTokenForMobile")
                let formvalues = {
                    email: await AES256_GCM_ENCRYPT(values.email),
                    password: await AES256_GCM_ENCRYPT(values.password)
                }
                clearLocalStorageExceptPersistRoot()
                // localStorage.clear();
                setTimeout(async () => {
                    if (fcmTokenForMobile) {
                        formvalues.fcmToken = fcmTokenForMobile
                        await dispatch(login({ formvalues, navigate, toast }))
                    } else {
                        await dispatch(login({ formvalues, navigate, toast }))
                    }
                }, 1000)
            }
        }
    })

    const handleClickShowPassword = () => setShowPassword(!showPassword)
    const handleMouseDownPassword = () => setShowPassword(!showPassword)
    const handleClickForgotPassword = () => {
        setFpassword(true)
        const obj = {
            email: formik.values.email
        }
        if (formik.values.email !== "") {
            const handleForgotPassword = async () => {
                try {
                    const response = await forgotPassword(obj)
                    if (response.data.status === false) {
                        toast.error(response.data.errorMessage)
                    }
                    if (response.data.status === true) {
                        toast.success(
                            "A reset password link has been sent to your primary email and secondary email"
                        )
                    }
                    setFpassword(false)
                } catch (error) {
                    toast.error(
                        error.response.message || "Please enter a valid email"
                    )
                }
            }
            handleForgotPassword()
        } else {
            formik.handleSubmit()
        }
    }

    useEffect(() => {
        const verifyMail = () => {
            if (
                Object.keys(formik.errors).length === 1 &&
                formik.errors.hasOwnProperty("password")
            ) {
                setShowVerified(true)
                setShowinvalid(false)
            } else if (
                formik.errors.email !== "Email is required" &&
                formik.errors.email !== "Enter a valid email" &&
                formik.values.password.length > 7
            ) {
                setShowVerified(true)
                setShowinvalid(false)
            } else if (
                Object.keys(formik.errors).length === 0 &&
                formik.values.email
            ) {
                setShowVerified(true)
                setShowinvalid(false)
            } else if (formik.errors.email === "Enter a valid email") {
                setShowVerified(false)
                setShowinvalid(true)
            } else {
                setShowVerified(false)
                setShowinvalid(false)
            }
        }
        verifyMail()
    }, [formik, fpassword])

    useEffect(() => {
        const processUserData = async () => {
            const hashData = await window.location.hash.slice(1)
            if (hashData) {
                setsplashScreen(true)
                try {
                    const decryptedValue = await AES256_GCM_decrypt({
                        data: { encryptedValue: hashData }
                    })
                    if (decryptedValue) {
                        // If not present in local storage and present in query, set it
                        await localStorage.setItem("user", decryptedValue)
                        setTimeout(() => {
                            navigate(
                                process.env.REACT_APP_DOMAIN_NAME + "dashboard"
                            )
                        }, 1000)
                    } else {
                        setTimeout(() => {
                            navigate(
                                process.env.REACT_APP_DOMAIN_NAME + "login"
                            )
                        }, 2000)
                    }
                } catch (error) {
                    toast.warning(
                        "Please close the app completely, including removing it from the background, and then reopen it.",
                        {
                            toastId: customId
                        }
                    )
                }
            } else {
                setTimeout(() => {
                    setsplashScreen(false)
                }, 2000)
            }
        }
        processUserData()
        Array.from(document.querySelectorAll("input")).forEach(
            (input) => (input.value = "")
        ) // eslint-disable-next-line
    }, [])

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <ToastContainer limit={1} />
            {splashScreen && <AnimatedDotsModal open={splashScreen} />}
            {!splashScreen && (
                <Box
                    sx={{
                        height: "340px",
                        display: "flex",
                        justifyContent: "center",
                        margin: isMobileTab ? "20%" : "10%"
                    }}
                >
                    <form onSubmit={formik.handleSubmit}>
                        <section
                            className="form-copy"
                            style={{
                                textAlign: "left",
                                marginBottom: "25px",
                                color: isSmallDevice ? "white" : null
                            }}
                        >
                            <h2
                                style={{
                                    fontFamily: "Mona Sans",
                                    fontSize: "24px",
                                    fontWeight: 500
                                }}
                            >
                                Sign in
                            </h2>
                        </section>
                        <div className="input-container email">
                            {isMobileTab && (
                                <Typography
                                    variant="h6"
                                    style={{
                                        marginBottom: "5px",
                                        marginTop: "5px",
                                        fontSize: "13px",
                                        fontFamily: "Mona Sans",
                                        color: "white"
                                    }}
                                >
                                    Email address
                                </Typography>
                            )}
                            <TextField
                                size="small"
                                id="login_email"
                                InputLabelProps={{ shrink: true }}
                                name="email"
                                label={isSmallDevice ? "" : "Email address"}
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.email &&
                                    Boolean(formik.errors.email)
                                }
                                helperText={
                                    formik.touched.email && formik.errors.email
                                }
                                sx={{
                                    marginBottom: "10%",
                                    width: "300px",
                                    borderColor: matches ? "white" : "black",

                                    "& input::placeholder": {
                                        textOverflow: "ellipsis !important",
                                        color: matches
                                            ? "black !important"
                                            : "",
                                        opacity: 1
                                    },

                                    "& .MuiInputBase-root": {
                                        height: "40px !important",
                                        background:
                                            "#FFFFFF 0% 0% no-repeat padding-box",
                                        borderRadius: "8px"
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {showVerified && (
                                                <CheckSharpIcon
                                                    sx={{
                                                        color: "green",
                                                        fontSize: 20
                                                    }}
                                                />
                                            )}
                                            {showinvalid && (
                                                <CloseIcon
                                                    sx={{
                                                        color: "red",
                                                        fontSize: 20
                                                    }}
                                                />
                                            )}
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                        <div className="input-container password">
                            {isMobileTab && (
                                <Typography
                                    variant="h6"
                                    style={{
                                        marginBottom: "5px",
                                        marginTop: "5px",
                                        fontSize: "13px",
                                        fontFamily: "Mona Sans",
                                        color: "white"
                                    }}
                                >
                                    Password
                                </Typography>
                            )}
                            <TextField
                                size="small"
                                id="login_password"
                                name="password"
                                label={isSmallDevice ? "" : "Password"}
                                InputLabelProps={{ shrink: true }}
                                type={showPassword ? "text" : "password"}
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                disabled={fpassword === true ? true : false}
                                error={
                                    fpassword
                                        ? null
                                        : formik.touched.password &&
                                          Boolean(formik.errors.password)
                                }
                                helperText={
                                    fpassword
                                        ? null
                                        : formik.touched.password &&
                                          formik.errors.password
                                }
                                sx={{
                                    marginBottom: "10%",
                                    width: "300px",
                                    "& input::placeholder": {
                                        textOverflow: "ellipsis !important",
                                        color: "#363636",
                                        opacity: 1
                                    },
                                    "& .MuiInputBase-root": {
                                        height: "40px !important",
                                        background:
                                            "#FFFFFF 0% 0% no-repeat padding-box",
                                        borderRadius: "8px"
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={
                                                    handleClickShowPassword
                                                }
                                                onMouseDown={
                                                    handleMouseDownPassword
                                                }
                                            >
                                                {showPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>

                        <Button
                            className="signin-button PrimaryColor"
                            disabled={
                                (formik.errors.email &&
                                    formik.errors.password) ||
                                fpassword === true ||
                                isLoading
                                    ? true
                                    : false
                            }
                            fullWidth
                            variant="contained"
                            type="submit"
                            sx={{
                                "&.Mui-disabled": {
                                    color: "gray.300"
                                }
                            }}
                            style={{
                                backgroundColor: "#21ba56",
                                fontFamily: "Mona Sans"
                            }}
                        >
                            {isLoading ? (
                                <>
                                    <CircularProgress
                                        sx={{
                                            color: "gray.300",
                                            marginRight: 1
                                        }}
                                        size={22}
                                    />
                                    <span>Sign in</span>
                                </>
                            ) : (
                                "Sign in"
                            )}
                        </Button>
                        <Link
                            onClick={handleClickForgotPassword}
                            color={isSmallDevice ? "#ffffff" : "#000000"}
                            sx={{
                                display: "block",
                                textAlign: "center",
                                marginTop: "5%",
                                cursor: "pointer",
                                fontFamily: "Mona Sans",
                                fontSize: "12px"
                            }}
                        >
                            {"Forgot password?"}
                        </Link>
                    </form>
                    <ToastContainer limit={1} />
                </Box>
            )}
        </div>
    )
}

export default Login
